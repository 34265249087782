<template>
  <PublicFooter
    v-if="!authStore.user"
  />
</template>

<script lang="ts" setup>

import PublicFooter from '@/components/App/Footer/PublicFooter.vue';
import {
  useStore,
} from '@/store';

const authStore = useStore.auth;

</script>

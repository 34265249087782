<template>
  <footer
    class="w-full pb-3 pt-6 text-xs xs:text-sm sm:pt-8 sm:text-base
    lg:text-lg xl:text-xl"
  >
    <div class="container mx-auto max-w-7xl px-3 sm:px-4">
      <p
        class="text-center"
      >
        Copyright © {{ currentYear }} {{ CONFIG.appName }}, All Rights Reserved.
      </p>

      <DevelopedByWithLogo
        class="mt-2"
      />
    </div>
  </footer>
</template>

<script setup lang="ts">
import DevelopedByWithLogo from '@/components/App/DevelopedByWithLogo.vue';
import {
  CONFIG,
} from '@/helpers';

const currentYear = new Date().getFullYear();

</script>

<style scoped lang="scss">

.title {
  @apply text-sm sm:text-lg xl:text-2xl xs:text-base lg:text-xl;
}

.icon {
  @apply size-4 sm:size-5 xl:size-6;
}

</style>
